<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <!-- NOMBRE -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model.trim="nombreDocument"
                auto-grow
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombreDocument),
                    rules.maxLength(nombreDocument, 50),
                  ])
                "
                dense
                outlined
                label="Nombre"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- DESCRIPCION -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="descripcionDocument"
                auto-grow
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(descripcionDocument),
                    rules.maxLength(descripcionDocument, 250)
                  ])
                "
                dense
                outlined
                label="Descripción"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- Fecha vigencia desde -->
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedDesde"
                    label="Vigencia desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaSelectedDesde)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaSelectedDesde)) <=
                          new Date(parseDateToIso(fechaSelectedHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaSelectedDesde = formatDate(fechaDesde)"
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha vigencia hasta -->
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedHasta"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(fechaSelectedHasta)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaSelectedDesde)) <=
                          new Date(parseDateToIso(fechaSelectedHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaSelectedHasta = formatDate(fechaHasta)"
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Acciones de cancelar y guardar -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal"> Cancelar </v-btn>
            <v-btn
              type="submit"
              :disabled="!isFormValid"
              form="form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
export default {
  directives: { mask },
  props: {
    itemDocumentCobertura: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      rules: rules,
      formEditTitle: enums.titles.EDIT_DOCUMENT_TIPO_COBERTURA,
      formNewTitle: enums.titles.NEW_DOCUMENT_TIPO_COBERTURA,
      calendarIcon: enums.icons.CALENDAR,
      isFormValid: false,
      nombreDocument: "",
      descripcionDocument: "",
      fechaSelectedDesde: null,
      fechaSelectedHasta: null,
      menuFechaDesde: false,
      menuFechaHasta: false,
      fechaDesde: null,
      fechaHasta: null,
    };
  },
  created() {
    if (this.itemDocumentCobertura.tcobDocId != undefined)
      this.setDocumentCobertura();
    else this.newDocumentCobertura();
  },
  methods: {
    ...mapActions({
      saveDocumentCoberturaEspecial:
        "afiliaciones/saveDocumentCoberturaEspecial",
      setAlert: "user/setAlert",
    }),
    setDocumentCobertura() {
      (this.nombreDocument = this.itemDocumentCobertura.tcobDocNombre),
        (this.descripcionDocument = this.itemDocumentCobertura.tcobDocDescrip),
        (this.fechaSelectedDesde = this.itemDocumentCobertura.tcobDocDesde),
        (this.fechaDesde = this.parseDateToIso(
          this.itemDocumentCobertura.tcobDocDesde
        ));
      this.fechaSelectedHasta = this.itemDocumentCobertura.tcobDocHasta;
      this.fechaHasta = this.parseDateToIso(
        this.itemDocumentCobertura.tcobDocHasta
      );
    },
    newDocumentCobertura() {
      this.formEditTitle = this.formNewTitle;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        TipoCobDocId:
          this.itemDocumentCobertura.tcobDocId != undefined
            ? this.itemDocumentCobertura.tcobDocId
            : 0,
        TipoCobId: this.itemDocumentCobertura.idTipocob,
        TipoCobDocNombre: this.nombreDocument,
        TipoCobDocDescripcion: this.descripcionDocument,
        TipoCobDocFechaDesde: this.fechaDesde,
        TipoCobDocFechaHasta: this.fechaHasta,
      };
      const res = await this.saveDocumentCoberturaEspecial(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>