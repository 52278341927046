<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="8" class="py-0">
              <v-text-field
                v-model.trim="nombreTipoCobertura"
                label="Nombre"
                outlined
                clearable
                dense
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombreTipoCobertura),
                    rules.maxLength(nombreTipoCobertura, 50)
                  ])
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="codigoInterno"
                label="Código interno"
                outlined
                clearable
                dense
                :rules="[
                  rules.requiredTrim(codigoInterno),
                  rules.maxLength(codigoInterno, 3)
                ]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" md="6" class="py-0">
              <v-switch
                class="my-0"
                v-model="esCoberturaGral"
                label="Es cobertura general."
                @change="changeValueSwitch()"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" md="6" class="py-0">
              <v-switch
                class="my-0"
                :disabled="esCoberturaGral"
                v-model="esCoberturaDiscapacidad"
                label="Es cobertura de discapacidad."
              ></v-switch>
            </v-col>
            <v-col cols="3" md="6" class="py-0 my-0" v-if="esCoberturaDiscapacidad">
              <v-text-field
                class="my-0"
                v-model="prorrogaMeses"
                label="Prórroga"
                hint="Prórroga en meses"
                outlined
                clearable
                dense
                :rules="
                  esCoberturaDiscapacidad && prorrogaMeses
                    ? rules.positiveNumber.concat(rules.max99, [
                        rules.requiredTrim(prorrogaMeses),
                        rules.maxLength(prorrogaMeses, 2)
                      ])
                    : []
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" md="6" class="py-0">
              <v-switch
                class="my-0"
                v-model="esCertificadoEstudio"
                label="Es certificado de estudio."
                @change="changeValueSwitch()"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0"
                v-model="excluirFacturista"
                label="Excluir del alerta al facturista en las pantallas de carga de órdenes."
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0"
                v-model="exigirMedico"
                label="Exigir médico de referencia en la carga de cobertura especial por afiliado."
                @change="changeValueSwitch()"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0"
                :disabled="
                  esCoberturaGral || esCertificadoEstudio || exigirMedico
                "
                v-model="mostrarCoberturasEspeciales"
                label="Visible en las coberturas especiales de la consulta de afiliados web del sitio de autorizaciones."
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0"
                v-model="mostrarCoberturasEspecialesPendientes"
                label="Visible en las coberturas especiales de las órdenes pendientes de autorización."
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0"
                :disabled="esCoberturaGral"
                v-model="mostrarCoberturasEspMovMasivo"
                label="Disponible para movimientos masivos de padrones por tipo de cobertura especial."
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditTipoCoberturaEspecial",
  props: {
    itemTipoCobertura: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    formEditTitle: "Editar tipo de cobertura especial",
    rules: rules,
    isFormValid: false,
    //campos nuevo/editar
    nombreTipoCobertura: null,
    codigoInterno: null,
    esCoberturaGral: false,
    esCoberturaDiscapacidad: false,
    prorrogaMeses: 0,
    esCertificadoEstudio: false,
    excluirFacturista: false,
    exigirMedico: false,
    mostrarCoberturasEspeciales: false,
    mostrarCoberturasEspecialesPendientes: false,
    mostrarCoberturasEspMovMasivo: false
  }),
  created() {
    if (this.itemTipoCobertura != null) {
      this.setTipoCobertura(this.itemTipoCobertura);
    } else {
      this.newTipoCobertura();
    }
  },
  methods: {
    ...mapActions({
      saveTipoCoberturaEspecial: "afiliaciones/saveTipoCoberturaEspecial",
      setAlert: "user/setAlert"
    }),
    newTipoCobertura() {
      this.formEditTitle = "Nuevo tipo de cobertura especial";
    },
    setTipoCobertura(item) {
      this.nombreTipoCobertura = item.tcobNombre;
      this.codigoInterno = item.tcobCod;
      this.esCoberturaGral = item.tcobGeneral;
      this.esCoberturaDiscapacidad = item.tcobEsDiscapacidad;
      this.esCertificadoEstudio = item.esCertificadoDeEstudio;
      this.excluirFacturista = item.tcobExcluirAlertaFacRegOrd;
      this.exigirMedico = item.tcobExigePrestador;
      this.mostrarCoberturasEspeciales = item.tCobVerWeb;
      this.mostrarCoberturasEspecialesPendientes =
        item.tcobVerAuditorEnOrdenPendiente;
      this.mostrarCoberturasEspMovMasivo = item.tcobMostrarmovMasivo;
      this.prorrogaMeses = item.tcobProrrogaMeses;
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        idTipocob:
          this.itemTipoCobertura != null ? this.itemTipoCobertura.idTipocob : 0,
        tcobNombre: this.nombreTipoCobertura,
        tcobCod: this.codigoInterno,
        tcobGeneral: this.esCoberturaGral,
        tcobEsDiscapacidad: this.esCoberturaDiscapacidad,
        esCertificadoDeEstudio: this.esCertificadoEstudio,
        tcobExcluirAlertaFacRegOrd: this.excluirFacturista,
        tcobExigePrestador: this.exigirMedico,
        tCobVerWeb: this.mostrarCoberturasEspeciales,
        tcobVerAuditorEnOrdenPendiente: this
          .mostrarCoberturasEspecialesPendientes,
        tcobMostrarmovMasivo: this.mostrarCoberturasEspMovMasivo,
        tcobProrrogaMeses: this.esCoberturaDiscapacidad ? this.prorrogaMeses : 0
      };
      try {
        const res = await this.saveTipoCoberturaEspecial(data);
        if (res.status === 200) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        }
      } catch (error) {
        this.isFormValid = true;
        this.esCoberturaGral = false;
      }
    },
    changeValueSwitch() {
      switch (true) {
        case this.esCoberturaGral:
          this.esCoberturaDiscapacidad = false;
          this.mostrarCoberturasEspeciales = false;
          this.mostrarCoberturasEspMovMasivo = false;
          break;
        case this.esCertificadoEstudio:
          this.mostrarCoberturasEspeciales = false;
          break;
        case this.exigirMedico:
          this.mostrarCoberturasEspeciales = false;
        default:
          break;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
