<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form-heredar-document"
          form="form-heredar-document"
          id="form-heredar-document"
          @submit.prevent="saveHeredarDocumentacion()"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="tipoCoberturaItems"
                v-model="tipoCoberturaSelected"
                :search="searchCoberturaEspecial"
                item-key="idTipocob"
                show-select
                single-select
                dense
                class="elevation-1"
              >
                <template v-slot:[`item.tcobGeneral`]="{ item }">
                  <v-icon
                    v-if="item.tcobGeneral"
                    small
                    color="primary"
                    class="d-flex justify-center"
                  >
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="searchCoberturaEspecial"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template
                  v-slot:[`item.data-table-select`]="{ isSelected, select }"
                >
                  <v-simple-checkbox
                    :value="isSelected"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!tipoCoberturaSelected.length || !isFormValid"
          form="form-heredar-document"
          color="primary"
        >
          Heredar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
export default {
  props: {
    coberturaEspecialId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      rules: rules,
      formEditTitle: enums.titles.HEREDAR_DOCUMENT_TIPO_COBERTURA,
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      searchCoberturaEspecial: "",
      headers: [
        {
          text: "Nombre",
          value: "tcobNombre",
          align: "left",
          sortable: false,
        },
        {
          text: "Código interno",
          value: "tcobCod",
          align: "center",
          sortable: false,
        },
        {
          text: "Es cobertura general",
          value: "tcobGeneral",
          align: "center",
          sortable: false,
        }
      ],
      tipoCoberturaItems: [],
      tipoCoberturaSelected: [],
      isFormValid: false,
    };
  },
  created() {
    this.setCoberturasEspeciales();
  },
  methods: {
    ...mapActions({
      getCoberturasEspecialesParaDocumentacion: "afiliaciones/getCoberturasEspecialesParaDocumentacion",
      heredarDocumentsCoberturaEspecial:
        "afiliaciones/heredarDocumentsCoberturaEspecial",
      setAlert: "user/setAlert",
    }),
    async setCoberturasEspeciales() {
      const response = await this.getCoberturasEspecialesParaDocumentacion(this.coberturaEspecialId);
      this.tipoCoberturaItems = response;
    },
    async saveHeredarDocumentacion() {
      this.isFormValid = false;
      const data = {
        tipoCobToCopyId: this.coberturaEspecialId,
        tipoCobId: this.tipoCoberturaSelected[0].idTipocob,
      };
      const response = await this.heredarDocumentsCoberturaEspecial(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>