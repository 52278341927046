<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0">
        <span class="pl-1 primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-data-table
                :headers="headers"
                :items="documentsCobertura"
                class="elevation-1"
                :search="search"
                item-key="tcobDocId"
                :loading="isLoading"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" align="end" v-if="canCreate">
                        <v-btn
                          color="primary"
                          class="to-right"
                          outlined
                          @click="openModalHeredar()"
                        >
                          Heredar
                        </v-btn>
                      </v-col>
                      <v-col cols="1" align="end" v-if="canCreate">
                        <v-btn
                          color="primary"
                          class="to-right"
                          @click="openModal(1)"
                        >
                          Nuevo
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left v-if="canEdit">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="openModal(2, item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar documentación</span>
                  </v-tooltip>
                  <v-tooltip left v-if="canDelete">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deleteDocument(item.tcobDocId)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar documentación</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditDocumentacionCobertura
        :itemDocumentCobertura="itemDocumentCobertura"
        @closeAndReload="closeAndReload"
      ></EditDocumentacionCobertura>
    </v-dialog>
    <v-dialog
      v-if="openHeredar"
      v-model="openHeredar"
      max-width="80%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <HeredarDocumentacionCobertura
        :coberturaEspecialId="coberturaEspecialId"
        @closeAndReload="closeAndReload"
      ></HeredarDocumentacionCobertura>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'40%'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditDocumentacionCobertura from "@/components/modules/afiliaciones/afiliados/EditDocumentacionCobertura.vue";
import HeredarDocumentacionCobertura from "@/components/modules/afiliaciones/afiliados/HeredarDocumentacionCobertura.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
export default {
  components: {
    GoBackBtn,
    PageHeader,
    DeleteDialog,
    EditDocumentacionCobertura,
    HeredarDocumentacionCobertura,
  },
  props: {
    itemTipoCobertura: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      title: `${enums.titles.DOCUMENT_TIPO_COBERTURA}: ${this.itemTipoCobertura.tcobNombre}`,
      titleDelete: enums.titles.DELETE_DOCUMENT_TIPO_COBERTURA,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      isLoading: false,
      openModalEdit: false,
      openHeredar: false,
      showDeleteModal: false,
      loadingDeleteBtn: false,
      search: "",
      documentsCobertura: [],
      editDocumentCobertura: {},
      idDocumentCobertura: null,
      optionCode: "F4151A53-90DA-4BD9-B192-29F596DD497C",
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "tcobDocNombre",
          sortable: false,
        },
        {
          text: "Vigencia desde",
          align: "center",
          value: "tcobDocDesde",
          sortable: false,
        },
        {
          text: "Vigencia hasta",
          align: "center",
          value: "tcobDocHasta",
          sortable: false,
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  created() {
    this.setDocumentCoberturaEspecial();
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getPermissionsDocumentCoberturaEspecial:
        "afiliaciones/getPermissionsDocumentCoberturaEspecial",
      getDocumentsCoberturaEspecial:
        "afiliaciones/getDocumentsCoberturaEspecial",
      deleteDocumentCoberturaEspecial:
        "afiliaciones/deleteDocumentCoberturaEspecial",
      setAlert: "user/setAlert",
    }),
    async setDocumentCoberturaEspecial() {
      this.isLoading = true;
      const response = await this.getDocumentsCoberturaEspecial(
        this.itemTipoCobertura.idTipocob
      );
      this.documentsCobertura = response;
      this.isLoading = false;
    },
    async setPermisos() {
      const permisos = await this.getPermissionsDocumentCoberturaEspecial({
        optionCode: this.optionCode,
      });
      this.canCreate = permisos.canCreate;
      this.canEdit = permisos.canEdit;
      this.canDelete = permisos.canDelete;
    },
    openModalHeredar() {
      if (this.documentsCobertura.length > 0) {
        this.setAlert({
          type: "warning",
          message:
            "Debe eliminar la documentación actual para heredar una nueva.",
        });
        return;
      }
      this.openHeredar = true;
      this.coberturaEspecialId = this.itemTipoCobertura.idTipocob;
    },
    openModal(num, item) {
      this.openModalEdit = true;
      if (num == 1)
        this.itemDocumentCobertura = {
          idTipocob: this.itemTipoCobertura.idTipocob,
        };
      else if (num == 2) this.itemDocumentCobertura = item;
    },
    deleteDocument(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      const response = await this.deleteDocumentCoberturaEspecial(
        this.itemToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setDocumentCoberturaEspecial();
        this.loadingDeleteBtn = false;
      }
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.openHeredar = false;
      this.setDocumentCoberturaEspecial();
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>