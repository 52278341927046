<template>
  <v-container class="pt-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-data-table
          :headers="headers"
          :items="tipoCoberturaItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:[`item.tcobGeneral`]="{ item }">
            <v-icon
              v-if="item.tcobGeneral"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.tCobEsPmi`]="{ item }">
            <v-icon
              v-if="item.tCobEsPmi"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="goToCosegurosTipoCoberturaEspecial(item)"
                >
                  {{ coseguroIcon }}
                </v-icon>
              </template>
              <span>Coseguros</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openTopesCoberturaEspecial(item)"
                >
                  {{ topesIcon }}
                </v-icon>
              </template>
              <span>Topes</span>
            </v-tooltip>
            <v-tooltip left v-if="canOpenDocumentacion">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalDocumentacion(item)"
                >
                  {{ documentIcon }}
                </v-icon>
              </template>
              <span>Documentación</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar tipo de cobertura especial</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete && item.fPagoId != 1">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteTipoCoberturaEspecial(item.idTipocob)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar tipo de cobertura especial</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-if="openModalEdit"
          v-model="openModalEdit"
          max-width="55%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <EditTipoCoberturaEspecial
            :itemTipoCobertura="itemTipoCobertura"
            @closeAndReload="closeAndReload"
          ></EditTipoCoberturaEspecial>
        </v-dialog>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openDocumentacion"
      v-model="openDocumentacion"
      max-width="90%"
      @keydown.esc="closeAndReload()"
      persistent
    >
      <DocumentacionCoberturaEspecial
        :itemTipoCobertura="itemTipoCobertura"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :isLoading="loadDeleteBtn"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      :maxWidth="'40%'"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import EditTipoCoberturaEspecial from "@/components/modules/afiliaciones/afiliados/EditTipoCoberturaEspecial.vue";
import DocumentacionCoberturaEspecial from "@/components/modules/afiliaciones/afiliados/DocumentacionCoberturaEspecial.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "TipoCoberturaEspecial",
  components: {
    PageHeader,
    GoBackBtn,
    DeleteDialog,
    EditTipoCoberturaEspecial,
    Ayuda,
    DocumentacionCoberturaEspecial
  },
  data: () => ({
    documentIcon: enums.icons.FILE_CHECK,
    coseguroIcon: enums.icons.MULTIPLE_CHECK,
    optionCode: enums.webSiteOptions.TIPOS_COBERTURAS_ESPECIALES,
    editIcon: enums.icons.EDIT,
    topesIcon: enums.icons.STETHOSCOPE,
    deleteIcon: enums.icons.DELETE,
    title: enums.titles.TIPO_COBERTURA_ESPECIAL,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    isLoading: false,
    showIcon: true,
    showHelp: false,
    showExpand: false,
    routeToGo: "CoberturasEspeciales",
    rules: rules,
    search: "",
    idToDelete: null,
    showDeleteModal: false,
    titleDelete: "¿Eliminar tipo de cobertura especial?",
    itemTipoCobertura: null,
    tipoCoberturaItems: [],
    headers: [
      {
        text: "Nombre",
        value: "tcobNombre",
        align: "left",
        sortable: false
      },
      {
        text: "Código interno",
        value: "tcobCod",
        align: "left",
        sortable: false
      },
      {
        text: "Es cobertura general",
        value: "tcobGeneral",
        align: "center",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    loadDeleteBtn: false,
    allowedActions: null,
    canCreate: false,
    canDelete: false,
    canEdit: false,
    openModalEdit: false,
    canOpenDocumentacion: false,
    openDocumentacion: false
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
    this.loadTiposCoberturasEspeciales();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  methods: {
    ...mapActions({
      getTipoCoberturasEspeciales: "afiliaciones/getTipoCoberturasEspeciales",
      deleteTipoCobEspecial: "afiliaciones/deleteTipoCobEspecial",
      setAlert: "user/setAlert"
    }),
    async loadTiposCoberturasEspeciales() {
      this.isLoading = true;
      const response = await this.getTipoCoberturasEspeciales();
      this.tipoCoberturaItems = response;
      this.isLoading = false;
    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_TIPO_COBERTURA_ESPECIAL:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_TIPO_COBERTURA_ESPECIAL:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_TIPO_COBERTURA_ESPECIAL:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .DOCUMENTACION_TIPO_COBERTURA_ESPECIAL:
            this.canOpenDocumentacion = true;
            break;
          default:
            break;
        }
      });
    },
    openModal(item) {
      this.openModalEdit = true;
      this.itemTipoCobertura = item;
    },
    deleteTipoCoberturaEspecial(id) {
      this.idToDelete = id;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.loadDeleteBtn = true;
      try {
        const response = await this.deleteTipoCobEspecial(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadTiposCoberturasEspeciales();
          this.loadDeleteBtn = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadDeleteBtn = false;
      }
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.openDocumentacion = false;
      this.openCoseguros = false;
      this.loadTiposCoberturasEspeciales();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    openModalDocumentacion(item) {
      this.itemTipoCobertura = item;
      this.openDocumentacion = true;
    },
    goToCosegurosTipoCoberturaEspecial(item) {
      this.$router.push({
        name: "CosegurosEnTipoCoberturaEspecial",
        params: {
          itemTipoCobertura: item
        }
      });
    },
    openTopesCoberturaEspecial(item) {
      this.$router.push({
        name: "TopesCoberturaEspecial",
        params: {
          coberturaEspecialItem: item
        }
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    }
  }
};
</script>
