var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","rules":_vm.rules.required.concat([
                  _vm.rules.requiredTrim(_vm.nombreDocument),
                  _vm.rules.maxLength(_vm.nombreDocument, 50) ]),"dense":"","outlined":"","label":"Nombre","rows":"1"},model:{value:(_vm.nombreDocument),callback:function ($$v) {_vm.nombreDocument=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreDocument"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","rules":_vm.rules.required.concat([
                  _vm.rules.requiredTrim(_vm.descripcionDocument),
                  _vm.rules.maxLength(_vm.descripcionDocument, 250)
                ]),"dense":"","outlined":"","label":"Descripción","rows":"1"},model:{value:(_vm.descripcionDocument),callback:function ($$v) {_vm.descripcionDocument=$$v},expression:"descripcionDocument"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia desde","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaSelectedDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaSelectedHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaSelectedDesde)}},model:{value:(_vm.fechaSelectedDesde),callback:function ($$v) {_vm.fechaSelectedDesde=$$v},expression:"fechaSelectedDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaSelectedDesde = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia hasta","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaSelectedDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaSelectedHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaSelectedHasta)}},model:{value:(_vm.fechaSelectedHasta),callback:function ($$v) {_vm.fechaSelectedHasta=$$v},expression:"fechaSelectedHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaSelectedHasta = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }